import PropTypes from "prop-types";
import Backdrop from "@material-ui/core/Backdrop";
import {Alert} from "@material-ui/lab";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import KioskPopupPartial from "./partials/popup/KioskPopupPartial";
import ChargingStationPopupPartial from "./partials/popup/ChargingStationPopupPartial";
import EvChargingStationPopupPartial from "./partials/popup/EvChargingStationPopupPartial";
import ParkingPopupPartial from "./partials/popup/ParkingPopupPartial";
import {
    LOCATION_TYPE_CHARGING_STATION,
    LOCATION_TYPE_EV_CHARGING_STATION,
    LOCATION_TYPE_KIOSK, LOCATION_TYPE_PARKING
} from "../../logic/config";

const useStyles = makeStyles((theme) => ({
    formRoot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    btnScan: {
        position: "absolute",
        bottom: `50px`,
        width: `calc(100% - 30px)`,
    },
    howToImage:{
        maxWidth: `350px`,
        background: `#0f1820`
    },
    backdrop: {
        zIndex: 9999,
        backgroundColor: "rgb(0 0 0 / 85%)"
    },
    popupLocationInfo: {
        "& > .MuiAlert-message": {
            width: "100%"
        },
        position: "absolute",
        top: "calc(30%)",
        left: "10px",
        width: "calc(100% - 20px)",
        zIndex: 9999,
        backgroundColor: theme.palette.secondary.main,
    },
    fullWidth: theme.fullWidth,
    popupError: {
        position: "absolute",
        top: "65px",
        left: "10px",
        width: "calc(100% - 20px)",
        zIndex: 9999,
    },
    popupLink: {
        color: "white",
        textDecoration: "underline"
    },
    primaryBtn: {
        marginTop: "1rem",
        background: 'linear-gradient(90deg, #2FE98E 10%, #5AA1B4 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
    },
    secondaryBtn: {
        "&:hover, &:active": {
            background: '#C3C3C3',
        },
        marginTop: "1rem",
        background: '#C3C3C3',
        borderRadius: 3,
        border: 0,
        color: 'white',
        boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
        width: "100%"
    }
}));

function LocationInfoPopup(props) {
    const { location, isOpen, onClose } = props
    const classes = useStyles()
    const [showInfo, setShowInfo] = useState(true)
    const [locationObject, setLocationObject] = useState(null)
    useEffect(() => {
        setShowInfo(isOpen)
        setLocationObject(location.originalObject)
    }, [location, isOpen, setShowInfo, setLocationObject])

    const HandleClose = () => {
       setShowInfo(false)
       onClose()
    }

    const RenderPartial = (type) => {
        switch (type) {
            case LOCATION_TYPE_KIOSK:
                return <KioskPopupPartial location={location}/>
            case LOCATION_TYPE_CHARGING_STATION:
                return <ChargingStationPopupPartial location={location}/>
            case LOCATION_TYPE_EV_CHARGING_STATION:
                return <EvChargingStationPopupPartial location={location}/>
            case LOCATION_TYPE_PARKING:
                return <ParkingPopupPartial location={location}/>
            default:
                return <React.Fragment />
        }
    }

    return(
        <React.Fragment>
            {(showInfo && locationObject !== null) &&
                <React.Fragment>
                    <Backdrop className={classes.backdrop} open={showInfo}/>
                    <Alert className={classes.popupLocationInfo} variant="filled" onClose={HandleClose} severity="info" show={'false'}>
                        {RenderPartial(location.getType().getName())}
                    </Alert>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

LocationInfoPopup.propTypes = {
    location: PropTypes.any.isRequired,
    isOpen: PropTypes.any.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default LocationInfoPopup;