import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Button, Grid} from "@material-ui/core";
import config from "../../logic/config";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {useAuth} from "../../contexts/AuthContext";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import PageTitle from "../PageTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";
import {GetUserInfo, UpdateAssignedMerchant} from "../../logic/network";


const useStyles = makeStyles((theme) => ({
    primaryColor: {
        color: theme.palette.primary.main
    },
    fullWidth: theme.fullWidth
}));

const TableCell = withStyles({
    root: {
        // borderBottom: "none"
    }
})(MuiTableCell);

export default function AccountPage() {
    let history = useHistory();
    const classes = useStyles();
    const [userInfo, setUserInfo] = useState()
    const {currentUser, getUserInfo, getProfileType, updateUserInfo } = useAuth()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(  () => {
        if (!currentUser) {
            history.push("/login");
        }
        let userInfo = getUserInfo()
        if (userInfo) {
            setUserInfo(userInfo)
        }
        console.log("[Account page] user: ", config.user)
        console.log("[Account page] currentUser: ", currentUser)
        console.log("[Account page] userInfo: ", userInfo)
    }, [history, setUserInfo, getUserInfo, currentUser]);

    const HandleBack = () => {
        history.push("/settings");
    };

    const HandleDisconnectFromBusinessAccount = async () => {
        // Disconnect user from merchant
        setLoading(true)
        try {
            const assignMerchantResp = await UpdateAssignedMerchant(userInfo.id, null)
            if (assignMerchantResp.data?.code !== 1) {
                setError("Failed to disconnect from merchant!")
            }
            const respUserInfo = await GetUserInfo()
            if (respUserInfo.data?.user) {
                respUserInfo.data.user.merchantName = null
                updateUserInfo(respUserInfo.data.user)
                history.push("/settings/account");
            } else {
                setError("Failed to load user data.")
            }
        } catch (err) {
            setError("Failed to disconnect from merchant!")
            console.error(err)
        }
        setLoading(false)
    }

    const HandleConnectToBusinessAccount = () => {
        history.push("/settings/account/connect-to-business");
    }

    return (
        <React.Fragment>
            <PageTitle title={"Account"} backButtonCallback={HandleBack}/>
            {loading && <LinearProgress className={classes.fullWidth} color="primary" />}
            {error && <Alert variant="outlined" className={classes.fullWidth} severity="error" onClose={() => {setError(null)}}>{error}</Alert>}
            <Grid container direction="row" wrap={"wrap"} alignItems="center" justify="flex-start">
                <Grid item style={{width: "100%"}}>
                    {userInfo && <React.Fragment>
                    <TableContainer>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableBody>
                                <TableRow key={"phoneNumber"}>
                                    <TableCell align="left"><strong>Mobile</strong></TableCell>
                                    <TableCell align="right">{userInfo.mobile}</TableCell>
                                </TableRow>
                                <TableRow key={"nickname"}>
                                    <TableCell align="left"><strong>Nickname</strong></TableCell>
                                    <TableCell align="right">{userInfo.name}</TableCell>
                                </TableRow>
                                <TableRow key={"profileType"}>
                                    <TableCell align="left"><strong>Profile type</strong></TableCell>
                                    <TableCell align="right">{getProfileType()}</TableCell>
                                </TableRow>
                                {userInfo.merchantName &&
                                    <TableRow key={"merchantName"}>
                                        <TableCell align="left"><strong>Merchant</strong></TableCell>
                                        <TableCell align="right">{userInfo.merchantName}</TableCell>
                                    </TableRow>
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                        {config.user.isBusinessProfile() &&
                            <Grid item style={{marginTop: "1rem", width: "100%", textAlign: 'center'}}>
                                <Button className={classes.fullWidth} onClick={HandleDisconnectFromBusinessAccount} variant="contained" color={"primary"}>Disconnect from business account</Button>
                            </Grid>
                        }
                        {!config.user.isBusinessProfile() &&
                            <Grid item style={{marginTop: "1rem", width: "100%", textAlign: 'center'}}>
                                <Button className={classes.fullWidth} onClick={HandleConnectToBusinessAccount} variant="contained" color={"primary"}>Assign to business account</Button>
                            </Grid>
                        }

                    </React.Fragment>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}