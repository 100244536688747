import React, {useEffect} from "react";
import {Button} from "@material-ui/core";
import {useHistory, useLocation} from "react-router-dom";
import { Grid } from "@material-ui/core";
import {
    ConfirmRent,
    GetCabinetAvailabilityInfo,
    GetCabinetStatus,
    GetMerchantByReferralCode, GetUserInfo,
    UpdateAssignedMerchant
} from "../../../../logic/network"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";
import PageTitle from "../../../PageTitle";
import config from "../../../../logic/config";
import {useAuth} from "../../../../contexts/AuthContext";
import {BillingUnitToString} from "../../../../logic/utils";
import PropTypes from "prop-types";


export default function KioskConfirmPagePartial(props) {
    let history = useHistory();
    let location = useLocation();
    let { kioskId, referralCodeParam } = props;
    const [isValidKioskId, setIsValidKioskId] = React.useState(false)
    const [isValidReferralCode, setIsValidReferralCode] = React.useState(false)
    const [cabinetInfo, setCabinetInfo] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [merchantId, setMerchantId] = React.useState(null)
    const {getUserInfo, updateUserInfo } = useAuth()
    // const [merchantName, setMerchantName] = React.useState(null)

    const validateMerchantAccessCode = async (referralCode) => {
        let result = null
        try {
            const merchantInfoResp = await GetMerchantByReferralCode(referralCode)
            if (merchantInfoResp.data?.code !== 1) {
                setError("Invalid referral code!")
                setLoading(false)
                result = null
            } else {
                if (merchantInfoResp.data.merchantId !== null) {
                    setMerchantId(merchantInfoResp.data.merchantId)
                    // setMerchantName(merchantInfoResp.data.merchantName)
                    result = merchantInfoResp.data.merchantId
                } else {
                    setError("Invalid merchant referral code!")
                    setLoading(false)
                    result = null
                }
            }
        } catch (err) {
            setError("Failed to validate merchant referral code!")
            console.error(err)
            setLoading(false)
            result = null
        }

        return result
    }

    useEffect(() => {
        const asyncLoadFunction = async () => {
            setLoading(true)

            if (referralCodeParam) {
                let merchantInfoResult = await validateMerchantAccessCode(referralCodeParam)
                if (merchantInfoResult !== null) {
                    setIsValidReferralCode(true)
                } else {
                    setLoading(false)
                    return
                }
            }

            try {
                const respCabinetAvailabilityInfo = await GetCabinetAvailabilityInfo(kioskId)
                if (respCabinetAvailabilityInfo.data?.code !== 1) {
                    if (respCabinetAvailabilityInfo.data?.msg) {
                        setError(respCabinetAvailabilityInfo.data?.msg)
                    } else {
                        setError("Something wrong with kiosk")
                    }
                    setLoading(false)
                    return
                }
            } catch (err) {
                console.error(err)
                setError("Something wrong with kiosk")
                setLoading(false)
                return
            }

            try {
                const respCabinetStatus = await GetCabinetStatus(kioskId)
                if (respCabinetStatus.data?.code === 1) {
                    setCabinetInfo(respCabinetStatus.data)
                    setIsValidKioskId(true);
                } else {
                    setError("Failed to load kiosk data! Please try to scan again!")
                }
            } catch (err) {
                console.error(err)
                setError("Failed to load kiosk data! Please try to scan again!")
                setIsValidKioskId(false);
            }
            setLoading(false)
        }
        asyncLoadFunction();
    }, [kioskId, setIsValidKioskId, setCabinetInfo, referralCodeParam, history, location, isValidReferralCode]);

    const HandleBack = () => {
        history.push("/scan");
    };

    const HandleConfirm = async () => {
        setLoading(true)
        setError(null)

        // if user individual update to business
        try {
            if (!config.user.isBusinessProfile() && isValidReferralCode) {
                let userInfo = getUserInfo()
                const assignMerchantResp = await UpdateAssignedMerchant(userInfo.id, merchantId)
                if (assignMerchantResp.data?.code === 1) {
                    const respUserInfo = await GetUserInfo()
                    if (respUserInfo.data?.user) {
                        respUserInfo.data.user.uid = userInfo.uid
                        respUserInfo.data.user.firebaseToken = userInfo.firebaseToken
                        updateUserInfo(respUserInfo.data.user)
                    }
                }
            } else {
                // Redirect to add card
                if (config.user.getCardsCount() === 0 && !config.user.isBusinessProfile()) {
                    history.push({
                        pathname: "/cards/add",
                        state: {
                            from: location.pathname
                        }
                    })
                    setLoading(false)
                    return
                }
            }
        } catch (err) {
            setLoading(false)
            setError("Invalid QR code. Please try to scan again!")
            return
        }


        try {
            const resp = await ConfirmRent(kioskId)
            console.log(resp.data)
            if (resp.data?.code === 1) {
                history.push({
                    pathname: '/lease/result',
                    state: resp.data
                });
            } else {
                setError(resp.data?.msg)
            }
        } catch (err) {
            setError(err)
            console.error(err)
        }
        setLoading(false)
    };

    return(
        <React.Fragment>
            {loading && <LinearProgress color="primary" />}
            {error && <Alert variant="outlined" severity="error" onClose={() => {setError(null)}}>{error}</Alert>}
            <PageTitle title={"Confirm"} backButtonCallback={HandleBack}/>

            {isValidKioskId &&
            <React.Fragment>
                <Grid container direction="row" wrap={"wrap"} alignItems="center" justify="center">
                    <Grid item>
                        Please confirm info
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableBody>
                            <TableRow key="boxId">
                                <TableCell align="right"><strong>Kiosk:</strong></TableCell>
                                <TableCell align="left">{kioskId}</TableCell>
                            </TableRow>
                            <TableRow key="merchant">
                                <TableCell align="right"><strong>Merchant:</strong></TableCell>
                                <TableCell align="left">{cabinetInfo.merchant.name}</TableCell>
                            </TableRow>
                            {isValidReferralCode &&
                                <React.Fragment>
                                    <TableRow key="usage-type">
                                        <TableCell align="right"><strong>Usage Type:</strong></TableCell>
                                        <TableCell align="left">Business</TableCell>
                                    </TableRow>
                                    <TableRow key="business-rate">
                                        <TableCell align="right"><strong>Rate:</strong></TableCell>
                                        <TableCell align="left">Free</TableCell>
                                    </TableRow>
                                </React.Fragment>

                            }
                            {!referralCodeParam &&
                                <React.Fragment>
                                    <TableRow key={"blling-unit"}>
                                        <TableCell align="right"><strong>Billing Unit</strong></TableCell>
                                        <TableCell align="left">{BillingUnitToString(cabinetInfo.merchant.per_billingunit)}</TableCell>
                                    </TableRow>
                                    <TableRow key="free-usage">
                                        <TableCell align="right"><strong>Free Usage:</strong></TableCell>
                                        <TableCell align="left">{cabinetInfo.merchant.per_freetime} Minute(s)</TableCell>
                                    </TableRow>
                                    <TableRow key="rate">
                                        <TableCell align="right"><strong>Rate:</strong></TableCell>
                                        <TableCell align="left">${cabinetInfo.merchant.per_price || 0} / {cabinetInfo.merchant.per_billingtime || 0} {BillingUnitToString(cabinetInfo.merchant.per_billingunit)}(s)</TableCell>
                                    </TableRow>
                                </React.Fragment>
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container direction="row" wrap={"wrap"} alignItems="center" justify="center">
                    <Grid item>
                        {isValidReferralCode &&
                            "This is a business transaction. Usage is free!"
                        }
                        {(!referralCodeParam && cabinetInfo.merchant.averageprice !== null) &&
                            `* Maximum rental charges per day is $${cabinetInfo.merchant.averageprice}`
                        }
                    </Grid>
                </Grid>

            <Grid container direction="row" wrap={"wrap"} alignItems="center" justify="center" style={{paddingTop: '1rem'}}>
                <Grid item>
                    <Button disabled={loading} onClick={HandleConfirm} variant="contained" color={"primary"}>Rent confirm</Button>
                </Grid>
            </Grid>
            </React.Fragment>
            }
        </React.Fragment>
    );
}

KioskConfirmPagePartial.propTypes = {
    kioskId: PropTypes.string.isRequired,
    referralCodeParam: PropTypes.string,
};