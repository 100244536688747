import React from 'react';
import {
    Route,
    Redirect
} from "react-router-dom";
import { useAuth } from "../contexts/AuthContext"


export default function PrivateRoute({ children, ...rest }) {
    const { currentUser } = useAuth()
    // const regex = /^\/confirm\/(?<kioskID>[a-zA-Z0-9]+)((\/?)(?<refCode>[a-zA-Z0-9]+)?)/gmi;
    return (
        <Route {...rest} render={({ location }) => {
            try {
                if (location.pathname.startsWith("/confirm")){
                    let parts = location.pathname.split("/")
                    let refCode = null
                    if (parts.length === 4) {
                        refCode = parts[3]
                    }

                    if (refCode) {
                        return currentUser ? children
                                    : <Redirect to={{
                                    pathname: `/login/business/${refCode}`,
                                    state: {from: location}
                        }}/>
                    }
                }

                // console.log("[PrivateRoute] location.pathname:", location.pathname)
                // let regexp = new RegExp(regex);
                // let match = regexp.exec(location.pathname).groups;
                // console.log("[PrivateRoute] match:", match)
                // if (match) {
                //     if (match.refCode) {
                //         console.log("[PrivateRoute] refCode:", match.refCode)
                //         return currentUser ? children
                //             : <Redirect to={{
                //             pathname: `/login/business/${match.refCode}`,
                //             state: {from: location}
                //         }}/>
                //     }
                // }
            } catch (err) {
                console.log(err)
            }
        return currentUser ? children
            : <Redirect to={{
                pathname: '/login',
                state: { from: location }
            }} />
    }} />
    )
}