import React, {useEffect, useState} from "react";
import {Button} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import QrReader from 'react-qr-scanner'
import Typography from '@material-ui/core/Typography';
import { Grid } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import PageTitle from "../PageTitle";
import Alert from "@material-ui/lab/Alert";
import LinearProgress from "@material-ui/core/LinearProgress";
import {IsValidQrPrefix} from "../../logic/utils";
import {CreateLocationTypeByPrefix} from "../../logic/locations";


const useStyles = makeStyles((theme) => ({
    btnScan: {
        position: "absolute",
        bottom: `50px`,
        width: `calc(100% - 30px)`,
    },
    fullWidth: theme.fullWidth
}));

const btnPrimary = {
    marginTop: "1rem",
    background: 'linear-gradient(90deg, #2FE98E 10%, #5AA1B4 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
    width: "100%"
}

export default function ScanPage() {
    let history = useHistory()
    const classes = useStyles()
    let qrRef = React.createRef()
    const [loading, setLoading] = useState(false)
    const facingModes = ['rear', 'front']
    const [facingMode] = useState(facingModes[0])
    const [locationId, setLocationId] = useState("")
    const [locationType, setLocationType] = useState(null)
    const [merchantRefCode, setMerchantRefCode] = useState("")
    const [error, setError] = useState(null)
    const [hasPermissions, setHasPermissions] = useState(false)
    const [tryToGetPermissions, setTryToGetPermissions] = useState(true)
    const [showUploadButton, setShowUploadButton] = useState(false)

    const previewStyle = {
        display: "block",
        objectFit: "cover",
        height: "300px",
        width: "300px",
        top: "10px",
        overflow: "scroll",
    }

    useEffect(  () => {
        const getVideoAccess = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: false,
                    video: true
                })
                const videoTracks = stream.getVideoTracks()
                if (videoTracks) {
                    setHasPermissions(true)
                    setTryToGetPermissions(false)
                }
            } catch (error) {
                setError("Permission Denial: can't use the camera")
                setShowUploadButton(true)
            }
        }
        if (tryToGetPermissions) {
            getVideoAccess();
            setTryToGetPermissions(false)
        }

    }, [tryToGetPermissions, setTryToGetPermissions, setHasPermissions])

    const HandleBack = () => {
        history.push("/");
    };

    const handleScan = async (data) => {
        setLoading(true)
        setError(false)
        const prefixCharge = "/confirm"
        if (data != null) {
            setLocationId("")
            setMerchantRefCode("")
            setLocationType(null)
            if (data.indexOf(prefixCharge) >= 0) {
                if (data.indexOf(prefixCharge) >= 0){
                    let n = data.indexOf(prefixCharge);
                    let qrCodeParts = data.substring(n + prefixCharge.length+1)
                    let parts = qrCodeParts.split("/")
                    let locationIdPrefix = null
                    if (parts.length >= 1) {
                        setLocationId(parts[0])
                        locationIdPrefix = parts[0].substring(0, 4)
                    }
                    if (IsValidQrPrefix(locationIdPrefix)) {
                        let locationTypeObject = CreateLocationTypeByPrefix(locationIdPrefix)
                        setLocationType(locationTypeObject)
                        setMerchantRefCode("")
                        if (parts.length === 2) {
                            setMerchantRefCode(parts[1])
                        }
                    }
                }
            } else {
                setError("Failed to scan QR code. Please try again!")
            }
        } else {
            if (!hasPermissions) {
                setError("Failed to scan QR code. Please try again!")
            }
        }

        setLoading(false)
        return false
    }

    const handleScanError = (err) => {
        setLoading(false)
        console.error("On scan error!", err)
        setError("Failed to scan QR code. Please try again!")
    }

    const HandleConfirm = (event) => {
        event.preventDefault();
        if (merchantRefCode !== "") {
            history.push(`/confirm/${locationId}/${merchantRefCode}`);
        } else {
            history.push(`/confirm/${locationId}`);
        }

    }

    const HandleOpenImageDialog = () => {
        setLocationId("")
        setMerchantRefCode("")
        qrRef.current.openImageDialog()
    }

    const onLoadQrReader = (e) => {
        setLoading(false);
    }

    const HandleOnImageLoad = (e) => {
        setLoading(true)
    }

    return(
        <React.Fragment>
            {loading && <LinearProgress className={classes.fullWidth} color="primary" />}
            {error && <Alert variant="outlined" severity="error" onClose={() => {setError(null)}}>{error}</Alert>}
            <PageTitle title={"Scan QR code"} backButtonCallback={HandleBack}/>
            {(!hasPermissions && showUploadButton) &&
                <Grid container direction="column" wrap={"wrap"} alignItems="center" justify="center" style={{paddingBottom: '1rem'}}>
                    <Grid item>
                        <Button key="fileMode" type='primary' style={btnPrimary} onClick={HandleOpenImageDialog}>Upload file</Button>
                    </Grid>
                </Grid>
            }
            <Grid container direction="column" wrap={"wrap"} alignItems="center" justify="center" style={{marginBottom: "15px"}}>
                <Grid item>
                    <QrReader
                        ref={qrRef}
                        facingmode={facingMode}
                        onLoad={onLoadQrReader}
                        delay={500}
                        resolution={600}
                        style={previewStyle}
                        onError={handleScanError}
                        onScan={handleScan}
                        onImageLoad={HandleOnImageLoad}
                        legacyMode={!hasPermissions}
                    />
                </Grid>
                {(locationId !== "" && locationType) &&
                    <Grid container direction="column" wrap={"wrap"} alignItems="center" justify="center" style={{paddingTop: '10px'}}>
                        <Grid item>
                            <Typography style={{wordBreak: "break-all"}}>
                                <strong>ID:</strong> {locationId}
                            </Typography>
                            <Typography style={{wordBreak: "break-all"}}>
                                <strong>Type:</strong> {locationType.name}
                            </Typography>
                            {merchantRefCode !== "" &&
                                <Typography style={{wordBreak: "break-all"}}>
                                    <strong>Merchant reference code:</strong> {merchantRefCode}
                                </Typography>
                            }
                        </Grid>
                        <Button onClick={HandleConfirm} style={btnPrimary}>Confirm</Button>
                    </Grid>
                }
            </Grid>
        </React.Fragment>
    );
}