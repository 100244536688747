import React from 'react';
import {Grid} from "@material-ui/core";
import PageTitle from "../PageTitle";
import {useHistory} from "react-router-dom";

const TermsAndConditionPage = () => {
    let history = useHistory();

    const HandleBack = () => {
        history.push("/settings");
    };

    return (
        <React.Fragment>
            <PageTitle title={""} backButtonCallback={HandleBack}/>
            <Grid container direction="row" wrap={"wrap"} alignItems="center" justify="center">
                <h2 style={{marginTop: 0}}>Terms and Condition</h2>
            </Grid>
            <Grid container direction="row" wrap={"wrap"} alignItems="center" justify="flex-start">
                <Grid item style={{width: "100%"}} >
                    Terms and Condition
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
export default TermsAndConditionPage