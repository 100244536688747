import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {useAuth} from "../../contexts/AuthContext";
import PageTitle from "../PageTitle";
import {Button, Grid} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";
import {DeleteCard, GetMerchantInfo, GetUserInfo, UpdatePrimaryCard} from "../../logic/network";
import config from "../../logic/config";

const useStyles = makeStyles((theme) => ({
    formRoot: {
        '& > *': {
            marginBottom: theme.spacing(1),
            width: '100%',
        },
    },
    fullWidth: theme.fullWidth,
    showInfo: {
        maxWidth: "1250px",
        position: "absolute",
        width: "calc(100% - 35px)",
        bottom: "40px"
    }
}));

const TableCell = withStyles({

})(MuiTableCell);

export default function CardInfoPage() {
    let history = useHistory()
    let { cardId } = useParams()
    const classes = useStyles();
    const [card, setCard] = useState(null)
    const [isPrimaryCard, setIsPrimaryCard] = useState(false)
    const { currentUser, getUserInfo } = useAuth()
    const [successMessage, setSuccessMessage] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const { updateUserInfo } = useAuth()

    useEffect(() => {
        if (!currentUser) {
            history.push("/login");
        }

        let userInfo = getUserInfo()
        if (userInfo) {
            for (let i = 0; i < userInfo.cards.length; i++) {
                let card = userInfo.cards[i]
                if (card.id === cardId) {
                    setCard(card)
                    if (card.id === userInfo.primaryCard) {
                        setIsPrimaryCard(true)
                    }
                }
            }
        }


    }, [currentUser, getUserInfo, history, cardId]);

    const HandleBack = () => {
        history.push("/cards");
    };

    const HandleDeleteCard = async () => {
        if (!card) {
            return
        }
        setLoading(true)
        setError(null)
        try {
            const deleteResult = await DeleteCard(card.id)
            if (deleteResult.data?.code === 1) {
                // TODO: Refactor update user info
                let userInfo = config.getUserInfo()
                const respUserInfo = await GetUserInfo()
                if (respUserInfo.data?.user) {
                    respUserInfo.data.user.uid = userInfo.uid
                    respUserInfo.data.user.firebaseToken = userInfo.firebaseToken
                    respUserInfo.data.user.merchantName = null
                    try {
                        if (respUserInfo.data.user.assigned_merchant) {
                            const merchantInfo = await GetMerchantInfo(respUserInfo.data.user.assigned_merchant)
                            if (merchantInfo.data?.code === 1) {
                                respUserInfo.data.user.merchantName = merchantInfo.data.merchant.name
                            }
                        }
                    } catch (err) {
                        console.error(err)
                    }
                    updateUserInfo(respUserInfo.data.user)
                    setSuccessMessage(deleteResult.data?.msg)
                } else {
                    setError("Failed to load user data")
                    setLoading(false)
                    return
                }
            } else {
                setError("Failed to delete card!")
                setLoading(false)
            }
        } catch (err) {
            setError("Failed to delete card!")
            setLoading(false)
            console.error(err);
        }
        setLoading(false)
    }

    const HandleSetCardAsPrimary = async () => {
        if (!card) {
            return
        }
        setLoading(true)
        setError(null)
        try {
            const deleteResult = await UpdatePrimaryCard(card.id)
            if (deleteResult.data?.code === 1) {
                // TODO: Refactor update user info
                let userInfo = config.getUserInfo()
                const respUserInfo = await GetUserInfo()
                if (respUserInfo.data?.user) {
                    respUserInfo.data.user.uid = userInfo.uid
                    respUserInfo.data.user.firebaseToken = userInfo.firebaseToken
                    respUserInfo.data.user.merchantName = null
                    try {
                        if (respUserInfo.data.user.assigned_merchant) {
                            const merchantInfo = await GetMerchantInfo(respUserInfo.data.user.assigned_merchant)
                            if (merchantInfo.data?.code === 1) {
                                respUserInfo.data.user.merchantName = merchantInfo.data.merchant.name
                            }
                        }
                    } catch (err) {
                        console.error(err)
                    }
                    updateUserInfo(respUserInfo.data.user)
                    setSuccessMessage(deleteResult.data?.msg)
                } else {
                    setError("Failed to load user data")
                    setLoading(false)
                    return
                }
            } else {
                setError("Failed to set primary card!")
                setLoading(false)
            }
        } catch (err) {
            setError("Failed to set primary card!")
            setLoading(false)
            console.error(err);
        }
        setLoading(false)
    }


    return (
        <React.Fragment>
            {loading && <LinearProgress className={classes.fullWidth} color="primary" />}
            {error && <Alert variant="outlined" severity="error" onClose={() => {setError(null)}}>{error}</Alert>}

            <PageTitle title={"Cards"} backButtonCallback={HandleBack}/>
            {successMessage &&
            <React.Fragment>
                <Alert variant="outlined" severity="success">{successMessage}</Alert>
                <Grid item xs={12} style={{marginTop: '1rem'}}>
                    <Button style={{width: "100%"}} variant="contained" onClick={() => history.push("/cards")} color={"primary"}>Ok</Button>
                </Grid>
            </React.Fragment>
            }
            {!card && <Alert variant="outlined" severity="error" onClose={() => {setError(null)}}>Card not found!</Alert>}
            {(card && !successMessage) &&
                <Grid container direction="row" wrap={"wrap"} alignItems="center" justify="center">
                    <Grid item>
                        <TableContainer key={card.id} style={{marginBottom: "10px", width: "350px"}}>
                            <Table size="small">
                                <TableBody>
                                    <TableRow key="type">
                                        <TableCell align="left" colSpan={2}
                                                   style={{fontSize: "1.1rem"}}><strong>{card.brand} {isPrimaryCard ? "(primary)" : ""}</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="last4">
                                        <TableCell align="center" colSpan={3}
                                                   style={{fontSize: "1.2rem", textAlign: "center"}}>**** **** **** {card.last4}</TableCell>
                                    </TableRow>
                                    <TableRow key="cardInfo">
                                        <TableCell align="center" style={{fontSize: "0.7rem"}}>Expire</TableCell>
                                        <TableCell align="center" style={{fontSize: "0.7rem"}}>CVV</TableCell>
                                        <TableCell align="center" style={{fontSize: "0.7rem"}}>Holder Name</TableCell>
                                    </TableRow>
                                    <TableRow key="cardInfo2">
                                        <TableCell align="center"
                                                   style={{fontSize: "1rem"}}>{card.exp_month}/{card.exp_year}</TableCell>
                                        <TableCell align="center"
                                                   style={{fontSize: "1rem"}}><strong>***</strong></TableCell>
                                        <TableCell align="center"
                                                   style={{fontSize: "1rem"}}>{card.name.toUpperCase()}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    {!isPrimaryCard &&
                        <Grid item xs={12} style={{marginTop: '1rem'}}>
                            <Button style={{width: "100%"}} variant="contained" onClick={HandleSetCardAsPrimary}  color={"primary"}>Set as primary</Button>
                        </Grid>
                    }
                    <Grid item xs={12} style={{marginTop: '1rem'}}>
                        <Button style={{width: "100%"}} variant="contained" onClick={HandleDeleteCard} color={"primary"}>Delete card</Button>
                    </Grid>
                </Grid>
            }

        </React.Fragment>
    );
}