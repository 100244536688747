import React, { useContext, useState, useEffect } from "react"
import app, { auth } from "../firebase"
import firebase from "firebase/app";
import config from "../logic/config";


const AuthContext = React.createContext()
const ProfileTypeIndividual = "Individual"
const ProfileTypeBusiness = "Business"

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [userInfo, setUserInfo] = useState()

    const [loading, setLoading] = useState(true)

    function signup(email, password) {
        return auth.createUserWithEmailAndPassword(email, password)
    }

    function login(phoneNumber) {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('firebase-recapcha', {
                'size': 'invisible',
                'callback': (response) => {
                    console.log("[recaptchaVerifier] callback")
                },
                'error-callback' : function() {
                    console.log("[recaptchaVerifier] error-callback;")
                    window.recaptchaVerifier.reset();
                },
            }, app)
        }

        return auth.signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier)
    }

    function updateUserInfo(userInfo) {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        config.user.userInfo = userInfo
        setUserInfo(userInfo)
    }

    function getUserInfo() {
        let localStorageUserInfo = JSON.parse(localStorage.getItem('userInfo'))
        config.user.userInfo = localStorageUserInfo

        return localStorageUserInfo
    }

    function cleanupRecapcha() {
        window.recaptchaVerifier = null
        window.confirmationResult = null
    }

    function logout() {
        setCurrentUser(null)
        cleanupRecapcha()
        return auth.signOut()
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email)
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password)
    }

    function getProfileType() {
        let type = ProfileTypeIndividual
        if (config.user.isBusinessProfile()) {
            type = ProfileTypeBusiness
        }

        return type
    }

    useEffect( () => {

        const unsubscribeOnAuthStateChanged = auth.onAuthStateChanged(user => {
            if (user) {
                console.log("[auth] onAuthStateChanged")
                setCurrentUser(user)
                user.getIdToken().then(idToken => {
                    let userInfo = getUserInfo()
                    if (userInfo) {
                        userInfo.firebaseToken = idToken
                    } else {
                        userInfo = {firebaseToken: idToken}
                    }

                    updateUserInfo(userInfo)
                });
            }
            setLoading(false)
        })

        const unsubscribeOnIdTokenChanged = firebase.auth().onIdTokenChanged(function(user) {
            if (user) {
                console.log("[auth] onIdTokenChanged")
                setCurrentUser(user)
                user.getIdToken().then(idToken => {
                    let userInfo = getUserInfo()
                    if (userInfo) {
                        userInfo.firebaseToken = idToken
                    } else {
                        userInfo = {firebaseToken: idToken}
                    }

                    updateUserInfo(userInfo)
                });
            }
        });

        return [unsubscribeOnAuthStateChanged, unsubscribeOnIdTokenChanged]
    }, [])

    const value = {
        currentUser,
        userInfo,
        login,
        signup,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
        updateUserInfo,
        getUserInfo,
        getProfileType,
        cleanupRecapcha
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}