import { GeolocateControl, Map, Marker } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
    LOCATION_TYPE_CHARGING_STATION,
    LOCATION_TYPE_EV_CHARGING_STATION,
    LOCATION_TYPE_KIOSK,
    LOCATION_TYPE_PARKING
} from "../logic/config";


export default class LocationMap extends Component {
    static propTypes = {
        locations: PropTypes.array.isRequired,
        paddingTop: PropTypes.number.isRequired,
        onClick: PropTypes.func.isRequired,
        onDragEnd: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props)
        this.markers = {}
        this.handleClick = this.handleClick.bind(this)

        this.state = {
            locationLat: 26.94184881952455,
            locationLng: -80.13336003048181,
        };

    }


    componentDidMount() {
        this.map = new Map({
            container: this.container,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: {
                lng: this.state.locationLng,
                lat: this.state.locationLat
            },
            zoom: 12
        })

        this.geolocateControl = new GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true,
            fitBoundsOptions: {
                maxZoom: 12
            }
        })

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((pos) => {
                this.map.jumpTo({
                    center: [pos.coords.longitude, pos.coords.latitude],
                })
            });
        }

        this.map.addControl(this.geolocateControl, 'top-right')
        this.map.on('dragend', () => this.props.onDragEnd(this.map.getBounds()))
        this.props.onDragEnd(this.map.getBounds())
    }

    componentDidUpdate() {
        this.props.locations.forEach(l => {
            if (!this.markers[l.locationId]) {
                // const m = new Marker(l.getType().getColor()).setLngLat([l.getPosition().lng, l.getPosition().lat]).addTo(this.map)
                // m.location = l
                // m.getElement().addEventListener('click', () => this.handleClick(l.locationId))
                // m.getElement().style.cursor = 'pointer'

                let el = document.createElement('div');
                el.className = 'marker';
                el.style.cursor = 'pointer'
                el.style.width = '32px';
                el.style.height = '48px';
                let fileName = 'ch.png'
                switch (l.getType().getName()) {
                    case LOCATION_TYPE_CHARGING_STATION:
                        fileName = 'cs.png'
                        if (l.originalObject.type === "container") {
                            fileName = 'ch.png'
                        }
                        break;
                    case LOCATION_TYPE_KIOSK:
                        fileName = 'pb.png'
                        break;
                    case LOCATION_TYPE_EV_CHARGING_STATION:
                        fileName = 'ev.png'
                        break;
                    case LOCATION_TYPE_PARKING:
                        fileName = 'pk.png'
                        break;
                    default:
                        fileName = 'ch.png'
                }
                el.style.backgroundImage = `url(./images/map-icons/${fileName})`;

                el.addEventListener('click', () => this.handleClick(l.locationId))
                const m = new Marker(el).setLngLat([l.getPosition().lng, l.getPosition().lat])
                m.getElement().addEventListener('click', () => this.handleClick(l.locationId))
                m.addTo(this.map)
                this.markers[l.locationId] = m
            } else {
                this.markers[l.locationId].location = l
            }
        })
    }

    componentWillUnmount() {
        this.map.remove()
    }

    handleClick(locationId) {
        const m = this.markers[locationId]

        this.map.jumpTo({
            center: m.getLngLat(),
            zoom: 15
        })

        // const c = this.map.getCanvas()
        const p = this.map.project(m.getLngLat())

        this.map.jumpTo({
            center: this.map.unproject([p.x, p.y]),
            zoom: 15
        })

        this.props.onClick(m.location)
    }

    render() {
        return (
            <div ref={el => this.container = el} style={{width: '100%', height: `calc(100% - ${this.props.paddingTop}px)`, position: 'absolute', bottom: 0, left: 0}}/>
        )
    }
}