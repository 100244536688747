import React, {
    useState,
    useMemo, useEffect
} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";
import PageTitle from "../PageTitle";
import {
    Button, Grid,
    TextField
} from "@material-ui/core";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js";
import StripeInput from "../../components/cards/StripeInput";
import {makeStyles} from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import {GetMerchantInfo, GetUserInfo, RegisterCard} from "../../logic/network";
import config from "../../logic/config";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
    formRoot: {
        '& > *': {
            marginBottom: theme.spacing(1),
            width: '100%',
        },
    },
    fullWidth: theme.fullWidth,
    showInfo: {
        maxWidth: "1250px",
        position: "absolute",
        width: "calc(100% - 35px)",
        bottom: "40px"
    }
}));

const useOptions = () => {
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize: '1.2rem',
                    color: '#ffffff',
                },
                invalid: {
                    fontWeight: 400,
                    color: '#e2554a',
                },
            },
        }),
        []
    );

    return options;
};

const cardFormValidationFields = {
    cardHolderName: false,
    cardNumber: false,
    expirationDate: false,
    cvv: false
}

export default function AddCardPage() {
    let history = useHistory();
    let location = useLocation();
    const classes = useStyles();
    const [successMessage, setSuccessMessage] = React.useState(null)
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = useState(false)
    const [isFormValid, setIsFormValid] = useState(false)
    const [cardHolderName, setCardHolderName] =useState("")
    const stripe = useStripe();
    const elements = useElements();
    const { updateUserInfo } = useAuth()
    const options = useOptions();
    const [formValidation, setFormValidation] = useState(cardFormValidationFields)

    useEffect(() => {
        setIsFormValid(false)
    }, [setIsFormValid])

    const HandleBack = () => {
        history.push("/cards");
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!isFormValid) {
            setError("Please fill all fields in the form!")
            return
        }

        setError(null)
        setLoading(true)

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            setLoading(false)
            return;
        }

        // Create Token
        const result = await stripe.createToken(
            elements.getElement(CardNumberElement),
            {
                name: cardHolderName
            }
        );
        if (result.error) {
            setError(result.error.message)
            console.error(result.error.message);
        } else {
            try {
                const registerResult = await RegisterCard(result.token.id)
                if (registerResult.data?.code === 1) {
                    // TODO: Refactor update user info
                    let userInfo = config.getUserInfo()
                    const respUserInfo = await GetUserInfo()
                    if (respUserInfo.data?.user) {
                        respUserInfo.data.user.uid = userInfo.uid
                        respUserInfo.data.user.firebaseToken = userInfo.firebaseToken
                        respUserInfo.data.user.merchantName = null
                        try {
                            if (respUserInfo.data.user.assigned_merchant) {
                                const merchantInfo = await GetMerchantInfo(respUserInfo.data.user.assigned_merchant)
                                if (merchantInfo.data?.code === 1) {
                                    respUserInfo.data.user.merchantName = merchantInfo.data.merchant.name
                                }
                            }
                        } catch (err) {
                            console.error(err)
                        }
                        updateUserInfo(respUserInfo.data.user)
                        setSuccessMessage(registerResult.data?.msg)
                        if (location.state?.from) {
                            history.push(location.state?.from)
                        }
                    } else {
                        setError("Failed to load user data.")
                        setLoading(false)
                        return
                    }
                } else {
                    setError(registerResult.data.msg.raw.message)
                    setLoading(false)
                }
            } catch (err) {
                setError("Failed to add card!")
                setLoading(false)
                console.error(err);
            }
        }
        setLoading(false)
        event.target.blur()
    };

    const HandleChangeName = (value) => {
        let fields = formValidation
        fields.cardHolderName = value.length >= 3
        setFormValidation(fields)
        setCardHolderName(value)
        validateForm()
    }

    const validateForm  = () => {
        if (formValidation.cardHolderName && formValidation.cardNumber && formValidation.cvv && formValidation.expirationDate) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }

    const OnChangeCardField = (event) => {
        let fields = formValidation
        let value = !!event.complete
        switch (event.elementType) {
            case "cardNumber":
                fields.cardNumber = value;
                break;
            case "cardExpiry":
                fields.expirationDate = value;
                break;
            case "cardCvc":
                fields.cvv = value;
                break;
            default:
                break;
        }
        setFormValidation(fields)
        validateForm()
    }

    return (
        <React.Fragment>
            {loading && <LinearProgress className={classes.fullWidth} color="primary" />}
            {error && <Alert variant="outlined" severity="error" onClose={() => {setError(null)}}>{error}</Alert>}
            <PageTitle title={"Add Card"} backButtonCallback={HandleBack}/>
            <form className={classes.formRoot} noValidate autoComplete="off">
                {successMessage &&
                <React.Fragment>
                    <Alert variant="outlined" severity="success">{successMessage}</Alert>
                    <Grid item xs={12} style={{marginTop: '1rem'}}>
                        <Button style={{width: "100%"}} variant="contained" onClick={() => history.push("/cards")} color={"primary"}>Ok</Button>
                    </Grid>
                </React.Fragment>
                }
                {!successMessage &&
                    <React.Fragment>
                        <Grid container direction="row" wrap={"wrap"} alignItems="center" justify="space-between">
                            <Grid item xs={12} style={{marginBottom: "1rem"}}>
                                <TextField
                                    label="Cardholder Name"
                                    name="firstname"
                                    onChange={(e) => HandleChangeName(e.target.value)}
                                    required
                                    fullWidth
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item xs={12} style={{marginBottom: "1rem"}}>
                                <TextField
                                    label="Credit Card Number"
                                    name="ccnumber"
                                    required
                                    fullWidth
                                    InputLabelProps={{shrink: true}}
                                    InputProps={{
                                        inputComponent: StripeInput,
                                        inputProps: {
                                            component: CardNumberElement,
                                            options: options,
                                            onChange: OnChangeCardField
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5} sm={3}>
                                <TextField
                                    label="Expiration Date"
                                    name="ccexp"
                                    required
                                    fullWidth
                                    InputLabelProps={{shrink: true}}
                                    InputProps={{
                                        inputComponent: StripeInput,
                                        inputProps: {
                                            component: CardExpiryElement,
                                            options: options,
                                            onChange: OnChangeCardField
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5} sm={3}>
                                <TextField
                                    label="CVC"
                                    name="cvc"
                                    required
                                    fullWidth
                                    InputLabelProps={{shrink: true}}
                                    InputProps={{
                                        inputComponent: StripeInput,
                                        inputProps: {
                                            component: CardCvcElement,
                                            options: options,
                                            onChange: OnChangeCardField
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{marginTop: '1rem'}}>
                                <Button disabled={!isFormValid} style={{width: "100%"}} variant="contained" onClick={handleSubmit} color={"primary"}>Add card</Button>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </form>
        </React.Fragment>
    );
}