import moment from "moment";
import config from "./config";

export const OpenLinkInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const BillingUnitToString = (billingUnit) => {
    let billingUnitStr = ""
    switch (billingUnit) {
        case 1:
            billingUnitStr = "Hour";
            break;
        case 2:
            billingUnitStr = "Minute";
            break;
        default:
            billingUnitStr = "Hour";
            break;
    }

    return billingUnitStr
}

export const FormatDate = (date) => {
    let dateTime = moment(date)

    return dateTime.format("MM/DD/YYYY")
}

export const DateDiff = (dateStart, dateEnd) => {
    let ms = moment(dateEnd).diff(moment(dateStart));
    let d = moment.duration(ms);
    return d.hours()+":"+d.minutes()+":"+d.seconds();
}


export const DetectBrowser = () => {
    let test = function(regexp) {return regexp.test(window.navigator.userAgent)}
    switch (true) {
        case test(/edg/i): return "Microsoft Edge";
        case test(/trident/i): return "Microsoft Internet Explorer";
        case test(/firefox|fxios/i): return "Mozilla Firefox";
        case test(/opr\//i): return "Opera";
        case test(/ucbrowser/i): return "UC Browser";
        case test(/samsungbrowser/i): return "Samsung Browser";
        case test(/chrome|chromium|crios/i): return "Google Chrome";
        case test(/safari/i): return "Apple Safari";
        default: return "Other";
    }
}

export const IsValidQrPrefix = (prefix) => {
    let isValid = false
    if (config.qrCodeTypeMapping.hasOwnProperty(prefix.toUpperCase())) {
        isValid = true
    }

    return isValid
}