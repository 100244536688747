import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";
import React, {useEffect, useState} from "react";
import noImage from "../../../../assets/images/no-image.png";
import {useHistory} from "react-router-dom";
import {useAuth} from "../../../../contexts/AuthContext";
import {GetMerchantInfo} from "../../../../logic/network";
import PageTitle from "../../../PageTitle";
import {
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    withStyles
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../generic/TabPanel";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import {BillingUnitToString} from "../../../../logic/utils";
import config from "../../../../logic/config";
import Typography from "@material-ui/core/Typography";
import MuiTableCell from "@material-ui/core/TableCell";


const useStyles = makeStyles((theme) => ({
    primaryColor: {
        color: theme.palette.primary.main
    },
    fullWidth: theme.fullWidth,
    tabsRoot: {
        flexGrow: 1,
        width: '100%'
    },
    listRoot: {
        width: '100%',
    },
    inline: {
        display: 'inline',
    },
    menuItemDivider: {
        marginLeft: 0,
        width: "100%"
    },
    menuItemImage: {
        width: "150px",
        height: "100px",
        objectFit: "cover",
        marginRight: "1rem"
    }
}));

const TableCell = withStyles({
    root: {
        // borderBottom: "none"
    }
})(MuiTableCell);

function KioskInfoPagePartial(props) {
    const {location} = props
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)
    const [locationInfo, setLocationInfo] = useState(null)
    const [merchantInfo, setMerchantInfo] = useState(null)
    const classes = useStyles()
    const { currentUser } = useAuth()
    const [activeTab, setActiveTab] = useState(0)
    const [hasMerchantMenu, setHasMerchantMenu] = useState(false)
    const [hasMerchantPromo, setHasMerchantPromo] = useState(false)

    const HandleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    useEffect(  () => {
        setError(null)
        if (!currentUser) {
            history.push("/login");
        }

        if (!location) {
            history.push("/");
        }

        const fetchLocation = async () => {
            setLoading(true)
            try {
                const merchantInfoResp = await GetMerchantInfo(location.id)
                if (merchantInfoResp.data?.code === 1) {
                    let merchant = merchantInfoResp.data.merchant
                    setMerchantInfo(merchant)
                    if (merchant.menus.length > 0) {
                        setHasMerchantMenu(true)
                    }
                    if (merchant.vouchers.length > 0) {
                        setHasMerchantPromo(true)
                    }
                } else{
                    setError("Failed to load additional info!")
                }
                setLoading(false)
            } catch (err) {
                setError("Failed to load location info! Please try again!")
                setLoading(false)
                console.log(err)
            }
        }
        fetchLocation();
        if (location !== null) {
            setLocationInfo(location)
        }
    }, [location, history, currentUser]);

    const HandleBack = () => {
        history.push("/");
    };

    const setTabProperties = (index, disabled) => {
        return {
            id: `scrollable-prevent-tab-${index}`,
            'aria-controls': `scrollable-prevent-tabpanel-${index}`,
            disabled: disabled
        };
    }

    return(
        <React.Fragment>
            {loading && <LinearProgress className={classes.fullWidth} color="primary" />}
            {error && <Alert variant="outlined" className={classes.fullWidth} severity="error" onClose={() => {setError(null)}}>{error}</Alert>}
            <PageTitle title={"Location Info"} backButtonCallback={HandleBack}/>
            {(!loading && locationInfo) &&
            <Grid container direction="row" wrap={"wrap"} alignItems="center" justify="flex-start">
                {(locationInfo.picture.length > 0) &&
                <Grid item style={{width: "100%", textAlign: "center"}}>
                    {
                        locationInfo.picture.map((image, index)=>{
                            return <img style={{width: "120px", height:"auto"}} key={"img-"+index} alt={"img-"+index} src={image} />
                        })
                    }
                </Grid>
                }
                <Grid item style={{width: "100%", textAlign: "center"}}>
                    <h3>{locationInfo.name}</h3>
                </Grid>
                <Grid item style={{width: "100%", textAlign: "center"}}>
                    <Tabs
                        value={activeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={HandleTabChange}
                        aria-label="Location info">
                        <Tab label="Info" {...setTabProperties(0, false)}/>
                        <Tab label="Menu" {...setTabProperties(1, !hasMerchantMenu)}/>
                        <Tab label="Promotion" {...setTabProperties(2, !hasMerchantPromo)}/>
                    </Tabs>
                    <TabPanel value={activeTab} index={0}>
                        <Grid item style={{width: "100%", marginTop:"1rem", textAlign: "left"}}>
                            <TableContainer>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableBody>
                                        <TableRow key={"locationType"}>
                                            <TableCell align="right"><strong>Type</strong></TableCell>
                                            <TableCell align="left">PowerBanks Kiosk</TableCell>
                                        </TableRow>
                                        <TableRow key={"locationAddress"}>
                                            <TableCell align="right"><strong>Address</strong></TableCell>
                                            <TableCell align="left"><Link target={"_blank"} href={`https://www.google.com/maps/search/?api=1&query=${locationInfo.latitude},${locationInfo.longitude}`}>{locationInfo.addr}</Link></TableCell>
                                        </TableRow>
                                        <TableRow key={"locationPhone"}>
                                            <TableCell align="right"><strong>Phone</strong></TableCell>
                                            <TableCell align="left"> <Link href={"tel:" + locationInfo.phone}>{locationInfo.phone}</Link></TableCell>
                                        </TableRow>
                                        {(locationInfo.shopstart && locationInfo.shopend) &&
                                        <TableRow key={"locationBusinessHours"}>
                                            <TableCell align="right"><strong>Business Hours</strong></TableCell>
                                            <TableCell
                                                align="left"> {locationInfo.shopstart} - {locationInfo.shopend}</TableCell>
                                        </TableRow>
                                        }
                                        <TableRow key={"locationPerPrice"}>
                                            <TableCell align="right"><strong>Price</strong></TableCell>
                                            <TableCell align="left">${locationInfo.per_price || 0}</TableCell>
                                        </TableRow>
                                        <TableRow key={"locationBillingUnit"}>
                                            <TableCell align="right"><strong>Billing Unit</strong></TableCell>
                                            <TableCell align="left">{BillingUnitToString(locationInfo.per_billingunit)}</TableCell>
                                        </TableRow>
                                        <TableRow key={"locationBillingTime"}>
                                            <TableCell align="right"><strong>Billing Time</strong></TableCell>
                                            <TableCell align="left">${locationInfo.per_price || 0} / {locationInfo.per_billingtime || 0} {BillingUnitToString(locationInfo.per_billingunit)}(s)</TableCell>
                                        </TableRow>
                                        <TableRow key={"locationFreeTime"}>
                                            <TableCell align="right"><strong>Free Time</strong></TableCell>
                                            <TableCell align="left">{locationInfo.per_freetime} Minute(s)</TableCell>
                                        </TableRow>
                                        <TableRow key={"locationAvailableSlots"}>
                                            <TableCell align="right"><strong>Available Slots</strong></TableCell>
                                            <TableCell align="left">{locationInfo.total_available_slot || 0}</TableCell>
                                        </TableRow>
                                        <TableRow key={"locationAvailablePowerBank"}>
                                            <TableCell align="right"><strong>Available PowerBank</strong></TableCell>
                                            <TableCell align="left">{locationInfo.total_available_powerbank || 0}</TableCell>
                                        </TableRow>
                                        <TableRow key={"locationTotalOnlineKiosk"}>
                                            <TableCell align="right"><strong>Total Online Kiosk</strong></TableCell>
                                            <TableCell align="left">{locationInfo.total_online_kiosk || 0}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <Grid item style={{width: "100%",textAlign: "left"}}>
                            {hasMerchantMenu &&
                            <div>
                                <List className={classes.listRoot}>
                                    {merchantInfo.menus.map((menuItem, index) => {
                                        return (
                                            <React.Fragment key={"MenuItem" + index}>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemAvatar>
                                                        <React.Fragment>
                                                            {menuItem.menuItemImage !== "" &&
                                                                <img className={classes.menuItemImage} alt={menuItem.menuItemName} src={config.powerBanksS3BaseUrl+menuItem.menuItemImage}/>
                                                            }
                                                            {menuItem.menuItemImage === "" &&
                                                                <img src={noImage} alt={menuItem.menuItemName} className={classes.menuItemImage}/>
                                                            }
                                                        </React.Fragment>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={menuItem.menuItemName}
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography component="span" variant="body2" className={classes.inline} color="primary">
                                                                    ${menuItem.menuItemPrice}<br/>
                                                                </Typography>
                                                                {menuItem.description}
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider className={classes.menuItemDivider} variant="inset" component="li" />
                                            </React.Fragment>
                                        )
                                    })}
                                </List>
                            </div>
                            }
                        </Grid>
                    </TabPanel>
                    <TabPanel value={activeTab} index={2}>
                        <Grid item style={{width: "100%",textAlign: "left"}}>
                            {hasMerchantPromo &&
                            <div>
                                <List className={classes.listRoot}>
                                    {merchantInfo.vouchers.map((voucherItem, index) => {
                                        return (
                                            <React.Fragment key={"VoucherItem" + index}>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={voucherItem.voucherName}
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography component="span" variant="body2" className={classes.inline} color="primary">
                                                                    {voucherItem.status}
                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider className={classes.menuItemDivider} variant="inset" component="li" />
                                            </React.Fragment>
                                        )
                                    })}
                                </List>
                            </div>
                            }
                        </Grid>
                    </TabPanel>
                </Grid>

            </Grid>
            }
        </React.Fragment>
    );
}

KioskInfoPagePartial.propTypes = {
    location: PropTypes.any.isRequired,
};

export default KioskInfoPagePartial;