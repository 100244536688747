import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Button, Grid, TextField} from "@material-ui/core";
import config from "../../logic/config";
import {makeStyles} from "@material-ui/core/styles";
import {useAuth} from "../../contexts/AuthContext";
import PageTitle from "../PageTitle";
import {GetMerchantByReferralCode, GetUserInfo, UpdateAssignedMerchant} from "../../logic/network";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";


const useStyles = makeStyles((theme) => ({
    primaryColor: {
        color: theme.palette.primary.main
    },
    fullWidth: theme.fullWidth
}));

export default function AccountConnectToBusinessPage() {
    let history = useHistory();
    const classes = useStyles();
    const [userInfo, setUserInfo] = useState()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [merchantId, setMerchantId] = useState(null)
    const [merchantName, setMerchantName] = useState(null)
    const [accessCode, setAccessCode] = useState("")
    const [isValidAccessCode, setIsValidAccessCode] = useState(false)
    const {currentUser, getUserInfo, updateUserInfo } = useAuth()

    useEffect(  () => {
        if (!currentUser) {
            history.push("/login");
        }
        let userInfo = getUserInfo()
        if (userInfo) {
            setUserInfo(userInfo)
        }
        console.log("[Account connect to business page] user: ", config.user)
        console.log("[Account connect to business page] currentUser: ", currentUser)
        console.log("[Account connect to business page] userInfo: ", userInfo)
    }, [history, setUserInfo, getUserInfo, currentUser]);

    const HandleBack = () => {
        history.push("/settings/account");
    };


    const HandleChangeAccessCode = (value)=> {
        setError(null)
        setAccessCode(value)
    }

    const HandleValidateAccessCode = async () => {
        // Disconnect user from merchant
        setError(null)
        setLoading(true)
        try {
            const merchantInfoResp = await GetMerchantByReferralCode(accessCode)
            if (merchantInfoResp.data?.code !== 1) {
                setError("Invalid referral code!")
                setLoading(false)
                return
            } else {
                if (merchantInfoResp.data.merchantId !== null) {
                    setMerchantId(merchantInfoResp.data.merchantId)
                    setMerchantName(merchantInfoResp.data.merchantName)
                    setIsValidAccessCode(true)
                } else {
                    setError("Invalid access code!")
                    setLoading(false)
                    return
                }
            }
        } catch (err) {
            setError("Failed to validate access code!")
            console.error(err)
            setLoading(false)
        }

        setLoading(false)
    }

    const HandleConnectToBusinessAccount = async () => {
        // Connect user to merchant
        setLoading(true)
        setError(null)
        try {
            const assignMerchantResp = await UpdateAssignedMerchant(userInfo.id, merchantId)
            if (assignMerchantResp.data?.code !== 1) {
                setError("Failed to assign merchant!")
            }

            const respUserInfo = await GetUserInfo()
            if (respUserInfo.data?.user) {
                let userInfo = getUserInfo()
                respUserInfo.data.user.uid = userInfo.uid
                respUserInfo.data.user.firebaseToken = userInfo.firebaseToken
                respUserInfo.data.user.merchantName = null
                if (merchantName) {
                    respUserInfo.data.user.merchantName = merchantName
                }
                updateUserInfo(respUserInfo.data.user)
                history.push("/settings/account");
            } else {
                setError("Failed to load user data.")
            }

        } catch (err) {
            setError("Failed to assign merchant!")
            console.error(err)
        }
        setLoading(false)
    }

    return (
        <React.Fragment>
            <PageTitle title={"Account settings"} backButtonCallback={HandleBack}/>
            {loading && <LinearProgress className={classes.fullWidth} color="primary" />}
            {error && <Alert variant="outlined" className={classes.fullWidth} severity="error" onClose={() => {setError(null)}}>{error}</Alert>}
            {isValidAccessCode &&
            <React.Fragment>
                <Grid container direction="row" wrap={"wrap"} alignItems="center" justify="flex-start">
                    <Grid item style={{width: "100%", textAlign: 'center'}}>
                        <h1>Merchant name is</h1>
                        <h2>{merchantName}</h2>
                        <Button className={classes.fullWidth} onClick={HandleConnectToBusinessAccount} variant="contained" color={"primary"}>Assign</Button>
                    </Grid>
                </Grid>
            </React.Fragment>
            }
            {!isValidAccessCode &&
            <React.Fragment>
                <Grid container direction="row" wrap={"wrap"} alignItems="center" justify="flex-start">
                    <Grid item style={{width: "100%"}}>
                        {userInfo &&
                        <React.Fragment>
                            <Grid item style={{marginTop: "1rem", width: "100%", textAlign: 'center'}}>
                                <TextField className={classes.fullWidth} id="verificationCode" label="Employee access code" value={accessCode} onChange={(e) => HandleChangeAccessCode(e.target.value)}/>
                            </Grid>
                            <Grid item style={{marginTop: "1rem", width: "100%", textAlign: 'center'}}>
                                <Button className={classes.fullWidth} onClick={HandleValidateAccessCode} variant="contained" color={"primary"}>Assign to business account</Button>
                            </Grid>
                        </React.Fragment>
                        }
                    </Grid>
                </Grid>
            </React.Fragment>
            }
        </React.Fragment>
    );
}