import React, {useEffect, useState} from "react";
import LocationMap from '../components/LocationMap'
// eslint-disable-next-line
import {getKioskListByCoordinates, loadLocationsByCoordinates} from "../logic/network";
// eslint-disable-next-line
import {Button, Grid, Link} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CropFreeIcon from '@material-ui/icons/CropFree';
import {useHistory} from "react-router-dom";
// import howToImage from "../assets/images/charge-pb-howto@2x.png";
import Box from '@material-ui/core/Box';
import "../logic/config"
import config from "../logic/config";
import {useAuth} from "../contexts/AuthContext";
// import Backdrop from "@material-ui/core/Backdrop";
// eslint-disable-next-line
import {Alert, AlertTitle} from "@material-ui/lab";
import LocationInfoPopup from "./locations/LocationInfoPopup";

// import AwesomeSlider from 'react-awesome-slider';
// import withAutoplay from 'react-awesome-slider/dist/autoplay';
// import 'react-awesome-slider/dist/styles.css';
// const AutoplaySlider = withAutoplay(AwesomeSlider);

const btnPrimary = {
    background: 'linear-gradient(90deg, #2FE98E 10%, #5AA1B4 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
}

const useStyles = makeStyles((theme) => ({
    formRoot: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    btnScan: {
        position: "absolute",
        bottom: `50px`,
        width: `calc(100% - 30px)`,
    },
    howToImage:{
        maxWidth: `350px`,
        background: `#0f1820`
    },
    backdrop: {
        zIndex: 9999,
        backgroundColor: "rgb(0 0 0 / 85%)"
    },
    popupLocationInfo: {
        "& > .MuiAlert-message": {
            width: "100%"
        },
        position: "absolute",
        top: "calc(30%)",
        left: "10px",
        width: "calc(100% - 20px)",
        zIndex: 9999,
        backgroundColor: theme.palette.secondary.main,
    },
    fullWidth: theme.fullWidth,
    popupError: {
        position: "absolute",
        top: "65px",
        left: "10px",
        width: "calc(100% - 20px)",
        zIndex: 9999,
    },
    popupLink: {
        color: "white",
        textDecoration: "underline"
    },
    primaryBtn: {
        marginTop: "1rem",
        background: 'linear-gradient(90deg, #2FE98E 10%, #5AA1B4 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
    },
    secondaryBtn: {
        "&:hover, &:active": {
            background: '#C3C3C3',
        },
        marginTop: "1rem",
        background: '#C3C3C3',
        borderRadius: 3,
        border: 0,
        color: 'white',
        boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
        width: "100%"
    }
}));

export default function Home() {
    let history = useHistory();
    const [error, setError] = useState(null);
    const [showLocationPopupInfo, setShowLocationPopupInfo] = useState(null);
    const [loading, setLoading] = useState(false);

    const [locations, setLocations] = useState([]);
    // eslint-disable-next-line
    const [focusedLocation, setFocusedLocation] = useState(null);
    const classes = useStyles();
    const { currentUser } = useAuth()

    useEffect(() => {
        console.log("[Home page] user: ", config.user)
        console.log("[Home page] currentUser: ", currentUser)
    }, [currentUser]);

    const handleClick = async (location) => {
        console.log(location)
        setFocusedLocation(location)
        setShowLocationPopupInfo(true)
        // try {
        //     const resp = await contractorsLocationGet('station', location.id)
        //     setFocusedLocation(resp.data.location)
        //     setError(null)
        //     console.log(focusedLocation)
        // } catch (err) {
        //     console.error(err)
        //     setError(err)
        //     console.log(err)
        // }
    }

    const handleMove = async (bounds) => {
        if (!loading) {
            setLoading(true)
            let locations = await loadLocationsByCoordinates(bounds)
            setLocations(locations)
            setLoading(false)
        }
    }

    const HandleScan = async () => {
        history.push("/scan")
    }

    const HandleCloseFocusedLocation = () => {
        setShowLocationPopupInfo(false);
        setFocusedLocation(null)
    }

    return(
        <React.Fragment>
            {error && <Alert variant="standard" className={classes.popupError} severity="error" onClose={() => {setError(null)}}>{error}</Alert>}
            <Grid container justify="center">
                {/*<AutoplaySlider*/}
                {/*    play={true}*/}
                {/*    cancelOnInteraction={false}*/}
                {/*    interval={6000}*/}
                {/*    bullets={false}*/}
                {/*    organicArrows={false}>*/}
                {/*    <div data-src="https://charge-v1-bucket.s3.amazonaws.com/image/banner/20210605/banner2.jpg" />*/}
                {/*    <div data-src="https://charge-v1-bucket.s3.amazonaws.com/image/banner/20210605/banner2.jpg" />*/}
                {/*</AutoplaySlider>*/}
                {(showLocationPopupInfo && focusedLocation !== null) &&
                    <LocationInfoPopup onClose={HandleCloseFocusedLocation} isOpen={showLocationPopupInfo} location={focusedLocation} />
                }
                <Box display="flex" justifyContent="flex-start" m={1} p={1} bgcolor="background.color" style={{padding:0, margin: 0}}>
                    <LocationMap paddingTop={50} locations={locations} onClick={handleClick} onDragEnd={handleMove}/>
                </Box>
                <Button className={classes.btnScan} onClick={HandleScan} style={btnPrimary}><CropFreeIcon/>Scan</Button>
            </Grid>
        </React.Fragment>

    );
}