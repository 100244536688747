import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useAuth} from "../../../../contexts/AuthContext";
import PageTitle from "../../../PageTitle";
import {
    Grid,
    Link,
    makeStyles,
    withStyles
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Button from '@material-ui/core/Button';
import MuiTableCell from "@material-ui/core/TableCell";


const useStyles = makeStyles((theme) => ({
    primaryColor: {
        color: theme.palette.primary.main
    },
    fullWidth: theme.fullWidth,
    buttonGroup: {
        marginBottom: "0.5rem"
    },
    buttonGroupItem:{
      maxWidth: "40px"
    },
    btnSecondary: {
        marginTop: "1rem",
        color: theme.palette.primary.dark,
        width: "calc(100% - 15px)"
    },
}));

const TableCell = withStyles({
    root: {
        // borderBottom: "none"
    }
})(MuiTableCell);

function EvChargingStationInfoPagePartial(props) {
    const {location} = props
    let history = useHistory()
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [locationInfo, setLocationInfo] = useState(null)
    const classes = useStyles()
    const {currentUser} = useAuth()
    // eslint-disable-next-line
    const [successMessage, setSuccessMessage] = useState(null)

    useEffect(() => {
        setError(null)
        if (!currentUser) {
            history.push("/login");
        }

        if (!location) {
            history.push("/");
        }

        if (location !== null) {
            setLocationInfo(location)
        }
    }, [location, history, currentUser]);

    const HandleBack = () => {
        history.push("/");
    };

    return (
        <React.Fragment>
            {loading && <LinearProgress className={classes.fullWidth} color="primary"/>}
            {error && <Alert variant="outlined" className={classes.fullWidth} severity="error" onClose={() => {
                setError(null)
            }}>{error}</Alert>}
            <PageTitle title={"Location Info"} backButtonCallback={HandleBack}/>
            {successMessage &&
            <React.Fragment>
                <Alert variant="outlined" severity="success">{successMessage}</Alert>
                <Grid item xs={12} style={{marginTop: '1rem'}}>
                    <Button style={{width: "100%"}} variant="contained" onClick={() => history.push("/")}
                            color={"primary"}>Ok</Button>
                </Grid>
            </React.Fragment>
            }
            {(!loading && locationInfo && !successMessage) &&
            <Grid container direction="row" wrap={"wrap"} alignItems="center" justify="flex-start">
                <Grid item style={{width: "100%", textAlign: "center"}}>
                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <TableBody>
                                <TableRow key={"locationStationType"}>
                                    <TableCell align="right"><strong>Type</strong></TableCell>
                                    <TableCell align="left">EV Charging Station</TableCell>
                                </TableRow>
                                <TableRow key={"locationStationId"}>
                                    <TableCell align="right"><strong>ID</strong></TableCell>
                                    <TableCell align="left">{locationInfo.id || ""}</TableCell>
                                </TableRow>
                                <TableRow key={"locationStationTitle"}>
                                    <TableCell align="right"><strong>Title</strong></TableCell>
                                    <TableCell align="left">{locationInfo.AddressInfo.Title}</TableCell>
                                </TableRow>
                                <TableRow key={"locationAddress"}>
                                    <TableCell align="right"><strong>Address</strong></TableCell>
                                    <TableCell align="left"><Link target={"_blank"}
                                                                  href={`https://www.google.com/maps/search/?api=1&query=${locationInfo.AddressInfo.Latitude},${locationInfo.AddressInfo.Longitude}`}>{locationInfo.AddressInfo.AddressLine1}</Link></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <React.Fragment>
                        <Grid item style={{width: "100%", textAlign: "center"}}>
                            <h3>Connections</h3>
                        </Grid>
                        {locationInfo.Connections?.length > 0 &&
                        <Grid item style={{width: "100%", textAlign: "center"}}>
                            {locationInfo.Connections.map((item, index) =>
                                <Grid key={"lot" + index} item style={{textAlign: "left"}}>
                                    <TableContainer>
                                        <Table size="small" aria-label="a dense table">
                                            <TableBody>
                                                <TableRow key={"locationConnectionId"}>
                                                    <TableCell colSpan={2} align="left"><strong>#{index+1}</strong></TableCell>
                                                </TableRow>
                                                <TableRow key={"locationConnectionType"}>
                                                    <TableCell align="right"><strong>Connection Type:</strong></TableCell>
                                                    <TableCell align="left">{item.ConnectionType.FormalName}</TableCell>
                                                </TableRow>
                                                <TableRow key={"PowerKW"}>
                                                    <TableCell align="right"><strong>Power(KW):</strong></TableCell>
                                                    <TableCell align="left">{item.PowerKW}</TableCell>
                                                </TableRow>
                                                <TableRow key={"Amps"}>
                                                    <TableCell align="right"><strong>Amps:</strong></TableCell>
                                                    <TableCell align="left">{item.Amps}</TableCell>
                                                </TableRow>
                                                <TableRow key={"Voltage"}>
                                                    <TableCell align="right"><strong>Voltage:</strong></TableCell>
                                                    <TableCell align="left">{item.Voltage}v</TableCell>
                                                </TableRow>
                                                <TableRow key={"Quantity"}>
                                                    <TableCell align="right"><strong>Quantity:</strong></TableCell>
                                                    <TableCell align="left">{item.Quantity}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            )}
                        </Grid>
                        }
                    </React.Fragment>
                </Grid>
            </Grid>
            }
        </React.Fragment>
    );
}

EvChargingStationInfoPagePartial.propTypes = {
    location: PropTypes.any.isRequired,
};

export default EvChargingStationInfoPagePartial;