import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {Grid} from "@material-ui/core";
import {GetLeaseOrderInfo} from "../../logic/network";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {useAuth} from "../../contexts/AuthContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'
import PageTitle from "../PageTitle";
import {DateDiff} from "../../logic/utils";


const useStyles = makeStyles((theme) => ({
    primaryColor: {
        color: theme.palette.primary.main
    },
    fullWidth: theme.fullWidth,
}));

const TableCell = withStyles({
    root: {
        // borderBottom: "none"
    }
})(MuiTableCell);

export default function OrderInfoPage() {
    let history = useHistory();
    const location = useLocation();
    const [order, setOrder] = useState();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = useState(null)
    const classes = useStyles();
    const { currentUser } = useAuth()

    useEffect(  () => {
        setLoading(true)
        setError(null)
        if (!currentUser) {
             history.push("/login");
        }
        if (!location.state) {
            history.push("/orders");
        }

        const fetchOrder = async () => {
            try {
                const response = await GetLeaseOrderInfo(location.state.orderId)
                if (response.data?.leaseorder) {
                    setOrder(response.data.leaseorder)
                } else {
                    setError("Failed to load order info! Please try again!")
                }
                setLoading(false)
            } catch (err) {
                setError("Failed to load order info! Please try again!")
                setLoading(false)
                console.log(err)
            }
        }
        fetchOrder();

    }, [location, history, currentUser]);

    const HandleBack = () => {
        history.push("/orders");
    };

    return(
        <React.Fragment>
            {loading && <LinearProgress className={classes.fullWidth} color="primary" />}
            {error && <Alert variant="outlined" className={classes.fullWidth} severity="error" onClose={() => {setError(null)}}>{error}</Alert>}
            <PageTitle title={"Order info"} backButtonCallback={HandleBack}/>
            {order &&
                <Grid container direction="row" wrap={"wrap"} alignItems="center" justify="flex-start">
                    <Grid item style={{width: "100%"}}>
                        <TableContainer>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableBody>
                                    <TableRow key={"orderNumber"}>
                                        <TableCell align="right"><strong>Order #</strong></TableCell>
                                        <TableCell align="left" colSpan={3}>{order.order_no}</TableCell>
                                    </TableRow>
                                    <TableRow key={"orderCabinetId"}>
                                        <TableCell align="right"><strong>Cabinet Id</strong></TableCell>
                                        <TableCell align="left" colSpan={3}>{order.cabinetid}</TableCell>
                                    </TableRow>
                                    <TableRow key={"orderStatus"}>
                                        <TableCell align="right"><strong>Order status</strong></TableCell>
                                        <TableCell align="left" colSpan={3}> {order.endtime? "Completed" : "In progress"}</TableCell>
                                    </TableRow>
                                    <TableRow key={"orderStartDate"}>
                                        <TableCell align="right"><strong>Start date</strong></TableCell>
                                        <TableCell align="left" colSpan={3}>{moment(order.starttime).format("YYYY-MM-DD hh:mm:ss")}</TableCell>
                                    </TableRow>
                                    {order.endtime &&
                                        <React.Fragment>
                                            <TableRow key={"orderEndDate"}>
                                                <TableCell align="right"><strong>End date</strong></TableCell>
                                                <TableCell align="left" colSpan={3}>{moment(order.endtime).format("YYYY-MM-DD hh:mm:ss")}</TableCell>
                                            </TableRow>
                                            <TableRow key={"orderTotalTime"}>
                                                <TableCell align="right"><strong>Total time</strong></TableCell>
                                                <TableCell align="left" colSpan={3}>{DateDiff(order.starttime, order.endtime)}</TableCell>
                                            </TableRow>
                                            <TableRow key={"chargeService"}>
                                                <TableCell align="right" colSpan={3}><strong>Charge service *</strong></TableCell>
                                                <TableCell align="left">$ 1.49</TableCell>
                                            </TableRow>
                                            <TableRow key={"tax"}>
                                                <TableCell align="right" colSpan={3}><strong>Tax *</strong></TableCell>
                                                <TableCell align="left">$ 0.33</TableCell>
                                            </TableRow>
                                            <TableRow key={"total"}>
                                                <TableCell align="right" colSpan={3}><strong>Total</strong></TableCell>
                                                <TableCell align="left">$ {order.amount}</TableCell>
                                            </TableRow>
                                            <TableRow key={"discount"}>
                                                <TableCell align="right" colSpan={3}><strong>Discount</strong></TableCell>
                                                <TableCell align="left">$ {order.discountamount}</TableCell>
                                            </TableRow>
                                            <TableRow key={"orderAmount"}>
                                                <TableCell align="right" colSpan={3}><strong>Actual</strong></TableCell>
                                                <TableCell align="left">$ {order.payable_amount}</TableCell>
                                            </TableRow>
                                            <TableRow key={"blank"}>
                                                <TableCell style={{borderBottom: 0}}/>
                                                <TableCell style={{borderBottom: 0}}/>
                                                <TableCell style={{borderBottom: 0}}/>
                                                <TableCell style={{borderBottom: 0}}/>
                                            </TableRow>
                                        </React.Fragment>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}