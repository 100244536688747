import PropTypes from "prop-types";
import {AlertTitle} from "@material-ui/lab";
import {Button, Link} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    popupLink: {
        color: "white",
        textDecoration: "underline"
    },
    primaryBtn: {
        marginTop: "1rem",
        background: 'linear-gradient(90deg, #2FE98E 10%, #5AA1B4 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
    }
}));


function ChargingStationPopupPartial(props) {
    const {location} = props
    const classes = useStyles()
    const history = useHistory()
    const [locationInfo, setLocationInfo] = useState(null)
    const [available, setAvailable] = useState(0)

    useEffect(()=>{
        if(location) {
            setLocationInfo(location.originalObject)
            let availableCount = 0
            if (location.originalObject.type === "container") {
                for (let i=0; i<location.originalObject.reservables.length; i++){
                    let reservable = location.originalObject.reservables[i]
                    if (reservable.currentAvailability !== "unavailable") {
                        availableCount++
                    }
                }
                setAvailable(availableCount)
            }
        }
    }, [location, setLocationInfo])

    const HandleShowLocationInfo = () => {
        history.push({
            pathname: `/location/info`,
            state: {
                location: location
            }
        })
    }

    return (
        <React.Fragment>
            {locationInfo !== null &&
                <React.Fragment>
                    <AlertTitle>{"Charging "}{locationInfo.type || ""}</AlertTitle>
                    <p>
                        <strong>Address:</strong> <br/>
                        <Link className={classes.popupLink} target={"_blank"}
                              href={`https://www.google.com/maps/search/?api=1&query=${locationInfo.coordinates.lat},${locationInfo.coordinates.lng}`}>{locationInfo.address}</Link>
                        <br/>
                    </p>
                    <p>
                        <strong>Type:</strong> {locationInfo.type || ""}<br/>
                        {locationInfo.type === "station" &&
                            <React.Fragment>
                                <strong>Available:</strong> {locationInfo.stallsAvailable || 0}<br/>
                                <strong>Total:</strong> {locationInfo.stallsTotal || 0}<br/>
                            </React.Fragment>
                        }
                        {locationInfo.type === "container" &&
                            <React.Fragment>
                                <strong>Available:</strong> {available || 0}<br/>
                                <strong>Total:</strong> {locationInfo.reservables.length || 0}<br/>
                            </React.Fragment>
                        }
                    </p>
                    <Button className={classes.primaryBtn} onClick={HandleShowLocationInfo} style={{width: "100%"}}>More info</Button>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

ChargingStationPopupPartial.propTypes = {
    location: PropTypes.any.isRequired,
};

export default ChargingStationPopupPartial;