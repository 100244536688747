import PropTypes from "prop-types";
import {AlertTitle} from "@material-ui/lab";
import {Button, Link} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    popupLink: {
        color: "white",
        textDecoration: "underline"
    },
    primaryBtn: {
        marginTop: "1rem",
        background: 'linear-gradient(90deg, #2FE98E 10%, #5AA1B4 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .1)',
    }
}));


function KioskPopupPartial(props) {
    const {location} = props
    const classes = useStyles()
    const history = useHistory()
    const [locationInfo, setLocationInfo] = useState(null)

    useEffect(()=>{
        if(location) {
            setLocationInfo(location.originalObject)
        }
    }, [location, setLocationInfo])

    const HandleShowLocationInfo = () => {
        history.push({
            pathname: `/location/info`,
            state: {
                location: location
            }
        })
    }

    return (
        <React.Fragment>
            {locationInfo !== null &&
                <React.Fragment>
                    <AlertTitle>PowerBanks Kiosk</AlertTitle>
                    <p>
                        <strong>Name:</strong> {locationInfo.name}<br/>
                    </p>
                    <p>
                        <strong>Address:</strong> <br/>
                        <Link className={classes.popupLink} target={"_blank"}
                              href={`https://www.google.com/maps/search/?api=1&query=${locationInfo.latitude},${locationInfo.longitude}`}>{locationInfo.addr}</Link>
                        <br/>
                    </p>
                    <p>
                        <strong>Available Slots:</strong> {locationInfo.total_available_slot || 0}<br/>
                        <strong>Available PowerBank:</strong> {locationInfo.total_available_powerbank || 0}<br/>
                        <strong>Price:</strong> ${locationInfo.per_price || 0}<br/>
                        {(locationInfo.shopstart && locationInfo.shopend) &&
                        <React.Fragment>
                            <strong>Business Hours: </strong><br/>
                            {locationInfo.shopstart} - {locationInfo.shopend}
                        </React.Fragment>
                        }
                    </p>
                    <Button className={classes.primaryBtn} onClick={HandleShowLocationInfo} style={{width: "100%"}}>More info</Button>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

KioskPopupPartial.propTypes = {
    location: PropTypes.any.isRequired,
};

export default KioskPopupPartial;