import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {Grid} from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import config from "../../logic/config";
import {GetMessagesList} from "../../logic/network";
import {makeStyles} from "@material-ui/core/styles";
import {useAuth} from "../../contexts/AuthContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import PageTitle from "../PageTitle";
import Alert from "@material-ui/lab/Alert";


const useStyles = makeStyles((theme) => ({
    primaryColor: {
        color: theme.palette.primary.main
    },
}));

export default function MessagesPage() {
    let history = useHistory();
    const location = useLocation();
    const [messagesList, setMessagesList] = useState([])
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const classes = useStyles();
    const { currentUser } = useAuth()

    useEffect( () => {
        if (!currentUser) {
            history.push("/login");
        }
        setLoading(true)
        const fetchMessagesList = async () => {
            try {
                const response = await GetMessagesList(1)
                if (response.data?.inbox) {
                    setMessagesList(response.data.inbox)
                    config.user.setMessagesCount(response.data.inbox.length)
                }
                setLoading(false)
                if (response.data?.code !== 1) {
                    setError(response.data.msg.code)
                }
            } catch (err) {
                setLoading(false)
                console.log(err)
            }

        }
        fetchMessagesList();

    }, [location, history, currentUser]);

    const HandleBack = () => {
        history.push("/");
    };

    const HandleOpenMessage = (message) => {
        history.push({
            pathname: '/message/info',
            state: {
                message: message
            }
        });
    };

    return(
        <React.Fragment>
            {loading && <LinearProgress className={classes.fullWidth} color="primary" />}
            {error && <Alert variant="outlined" className={classes.fullWidth} severity="error" onClose={() => {setError(null)}}>{error}</Alert>}
            <PageTitle title={"Messages"} backButtonCallback={HandleBack}/>
            <Grid container direction="row" wrap={"wrap"} alignItems="center" justify="flex-start">
                <Grid item style={{width: "100%"}}>
                    {(!loading && messagesList.length === 0) &&
                        <Grid container direction="row" wrap={"wrap"} alignItems="center" justify="center">
                            <Grid item><h4>Nothing here yet</h4></Grid>
                        </Grid>
                    }
                    <List dense={true}>
                        {messagesList.map((message, index) => {
                            return (
                                <React.Fragment key={"ListItem" + index}>
                                    <ListItem component={Paper} style={{marginBottom: "10px", height: "4rem", overflowY: "auto"}} onClick={() => {HandleOpenMessage(message)}}>
                                        <ListItemText>
                                            <strong>{message.msgTitle}</strong> <br />
                                            {message.date}
                                        </ListItemText>
                                        <ListItemText style={{textAlign: "right"}} className={classes.primaryColor}>
                                            <ArrowForwardIosIcon style={{fontSize: 15}}/></ListItemText>
                                    </ListItem>
                                </React.Fragment>
                            )
                        })}
                    </List>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}