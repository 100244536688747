import React, {useState, useEffect} from 'react';
import './App.css';
import Container from '@material-ui/core/Container';
import MainMenuBar from './components/MainMenu'
import { AuthProvider } from "./contexts/AuthContext"
import {Alert, AlertTitle} from '@material-ui/lab';
import './logic/config'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import {messaging, onMessageListener} from './firebase';
import AddToHomescreen from 'react-add-to-homescreen';
import Home from "./components/Home";
import ScanPage from "./components/scan/ScanPage";
import ConfirmPage from "./components/scan/ConfirmPage";
import PrivateRoute from "./components/PrivateRoute"
import OrdersPage from "./components/orders/OrdersPage";
import OrderInfoPage from "./components/orders/OrderInfoPage";
import CardsPage from "./components/cards/CardsPage";
import AddCardPage from "./components/cards/AddCardPage";
import Backdrop from "@material-ui/core/Backdrop";
import {makeStyles} from "@material-ui/core/styles";
import MessagesPage from "./components/messages/MessagesPage";
import MessageInfoPage from "./components/messages/MessageInfoPage";
import HelpPage from "./components/help/HelpPage";
import LeaseResultPage from "./components/LeaseResultPage";
import SettingsPage from "./components/settings/SettingsPage";
import PrivacyPolicyPage from "./components/settings/PrivacyPolicyPage";
import TermsAndConditionPage from "./components/settings/TermsAndConditionPage";
import CheckBrowsersPage from "./components/settings/CheckBrowsersPage";
import AccountPage from "./components/settings/AccountPage";
import AccountConnectToBusinessPage from "./components/settings/AccountConnectToBusinessPage";
import SignUpBusiness from "./components/SignUpBusiness";
import SignUp from "./components/SignUp";
import Login from "./components/Login";
import LoginBusiness from "./components/LoginBusiness";
import config from "./logic/config";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardInfoPage from "./components/cards/CardInfoPage";
import LocationInfoPage from "./components/locations/LocationInfoPage";
import SubscriptionsPage from "./components/settings/SubscriptionsPage";
import {DetectBrowser} from "./logic/utils";
const stripePromise = loadStripe(config.stripePk);

const padding = 15
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 9999,
        backgroundColor: "rgb(0 0 0 / 85%)"
    },
    container: {
        padding: padding,
        paddingTop: 0,
        paddingBottom: 0
    },
    pushNotification: {
        position: "absolute",
        top: "calc(50% - 60px)",
        left: "10px",
        width: "calc(100% - 20px)",
        zIndex: 9999,
        backgroundColor: "#353e47"
    }
}));

function App() {
    const [showNotification, setShowNotification] = useState(false);
    const [notification, setNotification] = useState({title: '', body: ''});
    const [token, setToken] = useState("");
    const [isShowAddToHomeScreen, setIsShowAddToHomeScreen] = useState(false);
    const classes = useStyles();

    useEffect(()=> {
        if (messaging) {
            messaging.getToken().then((data) => {
                setToken(data)
            })
            if (token.length > 0) {
                console.log("Token: "+token)
            }
        }

        if (DetectBrowser() === "safari") {
            setIsShowAddToHomeScreen(true)
        }

    }, [setIsShowAddToHomeScreen, token])

    if (onMessageListener) {
        onMessageListener().then(payload => {
            setShowNotification(true);
            setNotification({
                title: payload.notification.title,
                body: payload.notification.body
            })
        }).catch(err => console.error('Failed to receive push notification: ', err));
    }


    const HandleCloseNotification = () => {
        setShowNotification(false);
        setNotification({title: '', body: ''})
    }

    const HandleAddToHomeScreen = () => {
        alert(`
    1. Open Share menu
    2. Tap on "Add to Home Screen" button`);
    };

    return (
      <React.Fragment>
          <Router>
              <AuthProvider>
                  <MainMenuBar/>
                  <Container className={classes.container}>
                      {showNotification &&
                      <React.Fragment>
                            <Backdrop className={classes.backdrop} open={showNotification}/>
                            <Alert className={classes.pushNotification} variant="filled" onClose={HandleCloseNotification} severity="info" show={'false'}>
                                <AlertTitle>{notification.title}</AlertTitle>
                               {notification.body}
                            </Alert>
                      </React.Fragment>
                      }
                      <Switch>
                          <PrivateRoute exact path="/cards">
                              <CardsPage />
                          </PrivateRoute>
                          <PrivateRoute exact path="/cards/add">
                              <Elements stripe={stripePromise}>
                                  <AddCardPage />
                              </Elements>
                          </PrivateRoute>
                          <PrivateRoute exact path="/cards/:cardId">
                              <Elements stripe={stripePromise}>
                                  <CardInfoPage />
                              </Elements>
                          </PrivateRoute>
                          <PrivateRoute path="/orders">
                              <OrdersPage />
                          </PrivateRoute>
                          <PrivateRoute path="/order/info">
                              <OrderInfoPage />
                          </PrivateRoute>
                          <PrivateRoute path="/messages">
                              <MessagesPage />
                          </PrivateRoute>
                          <PrivateRoute path="/message/info">
                              <MessageInfoPage />
                          </PrivateRoute>
                          <PrivateRoute path="/help">
                              <HelpPage />
                          </PrivateRoute>
                          <PrivateRoute exact path="/settings">
                              <SettingsPage />
                          </PrivateRoute>
                          <PrivateRoute exact path="/settings/privacy-policy">
                              <PrivacyPolicyPage />
                          </PrivateRoute>
                          <PrivateRoute exact path="/settings/terms-and-condition">
                              <TermsAndConditionPage />
                          </PrivateRoute>
                          <PrivateRoute exact path="/settings/account">
                              <AccountPage />
                          </PrivateRoute>
                          <PrivateRoute exact path="/settings/account/connect-to-business">
                              <AccountConnectToBusinessPage />
                          </PrivateRoute>
                          <PrivateRoute exact path="/settings/check-browser">
                              <CheckBrowsersPage />
                          </PrivateRoute>
                          <PrivateRoute path="/settings/subscriptions">
                              <SubscriptionsPage />
                          </PrivateRoute>

                          <Route exact path="/login">
                              <Login />
                          </Route>
                          <Route exact path="/login/business/:accessCodeParam?">
                              <LoginBusiness />
                          </Route>
                          <Route exact path="/signup/business/:accessCodeParam?">
                              <SignUpBusiness />
                          </Route>
                          <Route exact path="/signup/:referralCodeParam?">
                              <SignUp />
                          </Route>

                          <Route exact path="/logout">
                              <Redirect
                                  to={{
                                      pathname: "/login"
                                  }}
                              />
                          </Route>

                          <PrivateRoute path="/scan">
                              <ScanPage />
                          </PrivateRoute>
                          <PrivateRoute path="/confirm/:locationId/:referralCodeParam?">
                              <ConfirmPage />
                          </PrivateRoute>
                          <PrivateRoute path="/lease/result">
                              <LeaseResultPage />
                          </PrivateRoute>
                          <PrivateRoute exact path="/">
                              <Home/>
                          </PrivateRoute>
                          <PrivateRoute exact path="/location/info">
                              <LocationInfoPage/>
                          </PrivateRoute>
                          <Route path="*">
                              <Redirect
                                  to={{
                                      pathname: "/"
                                  }}
                              />
                          </Route>
                      </Switch>
                  </Container>
              </AuthProvider>
          </Router>
          {isShowAddToHomeScreen &&
            <AddToHomescreen icon={"/images/apple-icon-180.png"} title={"Install this app"} onAddToHomescreenClick={HandleAddToHomeScreen} />
          }
      </React.Fragment>
  );
}

export default App;
