import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Backdrop from '@material-ui/core/Backdrop';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import logo from "../assets/images/charge-logo-gradient.png";
import { useHistory } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import {Box} from "@material-ui/core";
// import IconCoins from '../assets/images/coins@2x.png'
// import IconTickets from '../assets/images/tickets@2x.png'
import config from "../logic/config";
import {useAuth} from "../contexts/AuthContext";
import MenuIcon from '@material-ui/icons/Menu';
import {OpenLinkInNewTab} from "../logic/utils";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        backgroundColor: '#0f1820',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    mainMenuListItem: {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        backgroundColor: '#0f1820',
        width: drawerWidth,
        overflowY: "scroll"
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 2),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    logo: {
        maxWidth: 60,
        margin: 'auto'
    },
    backdrop: {
        zIndex: 10,
        backgroundColor: "rgb(0 0 0 / 85%)"
    },
    userHeader: {
        height: 150,
        margin: 0,
        background: 'linear-gradient(90deg, #2FE98E 10%, #00b984 90%)',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10

    },
    userHeaderAvatar: {
        color: "#7c97ad",
        backgroundColor: "#0f1820",
        margin: "auto",
        marginBottom: "10px"
    },
    userName: {
        fontWeight: "bold",
        textAlign: "center"
    },
    userProfileType: {
        textAlign: "center"
    },
    userPoweredBy: {
        textAlign: "center",
        color: theme.palette.secondary.dark
    },
    mainMenuIconBlocks: {
        width: '95px',
        height: '95px',
        borderRadius: 5,
        backgroundColor: theme.palette.secondary.light
    },
    mainMenuIconBlocksTitle: {
        textAlign: "center",
        color: theme.palette.primary.main,
        marginTop: '10px'
    },
    mainMenuButton: {
        color: theme.palette.primary.main,
    }
}));

export default function PersistentDrawerLeft() {
    let history = useHistory();
    const { currentUser, getProfileType } = useAuth()
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);


    const handleDrawerOpen = () => {
        if (currentUser) {
            setOpen(true);
        }
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const HandleMenuAction = (link) => {
        setOpen(false);
        history.push(link)
    };

    const menuItems = [
        {
            title: "Cards",
            link: "/cards",
            newItemsCount: config.user.getCardsCount(),
            actionHandler: null,
            onlyForIndividual: false,
        },
        {
            title: "Orders",
            link: "/orders",
            newItemsCount: config.user.getOrdersCount(),
            actionHandler: null,
            onlyForIndividual: false,
        },
        {
            title: "Messages",
            link: "/messages",
            newItemsCount: config.user.getMessagesCount(),
            actionHandler: null,
            onlyForIndividual: false,
        },
        {
            title: "Help",
            link: "/help",
            newItemsCount: 0,
            actionHandler: null,
            onlyForIndividual: false,
        },
        {
            title: "Settings",
            link: "/settings",
            newItemsCount: 0,
            actionHandler: null,
            onlyForIndividual: false,
        },
        {
            title: "Subscriptions",
            link: "/settings/subscriptions",
            newItemsCount: 0,
            actionHandler: null,
            onlyForIndividual: false,
        },
        {
            title: "Need EV charger",
            link: "https://appointment.install.co/?v=0",
            newItemsCount: 0,
            actionHandler: OpenLinkInNewTab,
            onlyForIndividual: false,
        },
        {
            title: "Become EV installer",
            link: "https://network.install.co/",
            newItemsCount: 0,
            actionHandler: OpenLinkInNewTab,
            onlyForIndividual: false,
        },
    ]

    return (
        <div className={classes.root}>
            <Backdrop className={classes.backdrop} open={open} onClick={handleDrawerClose} />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}>
                <Toolbar>
                    {currentUser && <MenuIcon className={classes.mainMenuButton} onClick={handleDrawerOpen}/>}
                    <img src={logo} alt="charge" className={classes.logo} onClick={handleDrawerOpen} />
                    {(!open && currentUser) && <MenuIcon style={{visibility: "hidden"}}/>}
                </Toolbar>
            </AppBar>
            {currentUser && <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{ paper: classes.drawerPaper}}>
                <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="center" m={1} p={1} onClick={handleDrawerClose} className={classes.userHeader}>
                    <Box p={1}>
                        <Avatar className={classes.userHeaderAvatar}>
                            <PersonIcon />
                        </Avatar>
                        <Box className={classes.userName}>
                            {config.user.userInfo?.name || "User"}
                        </Box>
                        <Box className={classes.userProfileType}>
                            {getProfileType()}<br/>
                            {config.user.userInfo?.merchantName || ""}
                        </Box>
                        <Box className={classes.userPoweredBy}>
                            Powered by Charge
                        </Box>
                    </Box>
                </Box>
                {/* Coins and Tickets currently not used */}
                {/*{!config.user.isBusinessProfile() &&*/}
                {/*    <React.Fragment>*/}
                {/*        <Box display="flex" alignItems="center" justifyContent="center" m={1} p={1}>*/}
                {/*            <Box className={classes.mainMenuIconBlocks} style={{marginRight: 'auto', padding: "20px"}}>*/}
                {/*                <Grid container direction="column" wrap={"wrap"} alignItems="center" onClick={() => HandleMenuAction("/coins")}>*/}
                {/*                    <Grid item style={{textAlign: 'center'}}>*/}
                {/*                        <img alt={"coins"} src={IconCoins} height={30} width={30}/>*/}
                {/*                    </Grid>*/}
                {/*                    <Grid item className={classes.mainMenuIconBlocksTitle}>*/}
                {/*                        Coins*/}
                {/*                    </Grid>*/}
                {/*                </Grid>*/}
                {/*            </Box>*/}
                {/*            <Box className={classes.mainMenuIconBlocks} style={{marginRight: 'auto', padding: "20px"}}>*/}
                {/*                <Grid container direction="column" wrap={"wrap"} alignItems="center" onClick={() => HandleMenuAction("/tickets")}>*/}
                {/*                    <Grid item style={{textAlign: 'center'}}>*/}
                {/*                        <img alt={"tickets"} src={IconTickets} height={30} width={30}/>*/}
                {/*                    </Grid>*/}
                {/*                    <Grid item className={classes.mainMenuIconBlocksTitle}>*/}
                {/*                        Tickets*/}
                {/*                    </Grid>*/}
                {/*                </Grid>*/}
                {/*            </Box>*/}
                {/*        </Box>*/}
                {/*    </React.Fragment>*/}
                {/*}*/}

                <List>
                    <Divider component="li" variant="inset" style={{marginLeft: 15, marginRight: 15}}/>
                    {menuItems.map((menuItem, index) => {
                        let handler = HandleMenuAction
                        if (menuItem.actionHandler) {
                            handler = menuItem.actionHandler
                        }
                        if (menuItem.onlyForIndividual === true && config.user.isBusinessProfile() === true) {
                            // Skip menuItem
                            return ""
                        } else {
                            return (<React.Fragment key={index}>
                                <ListItem button key={menuItem.title} className={classes.mainMenuListItem} onClick={() => handler(menuItem.link)}>
                                    <ListItemText primary={menuItem.title} />
                                    {menuItem.newItemsCount > 0 &&
                                    <ListItemSecondaryAction>
                                        <div style={{fontWeight: "bold"}}>{menuItem.newItemsCount}</div>
                                    </ListItemSecondaryAction>
                                    }
                                </ListItem>
                                <Divider component="li" variant="inset" style={{marginLeft: 15, marginRight: 15}}/>
                            </React.Fragment>)
                        }

                    })}
                </List>
            </Drawer>}
            <div className={classes.drawerHeader} />
        </div>
    );
}